.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.exp-container{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height:100%;
  width: 100%;
  overflow-y: auto;

  padding-top: 80;
}

.button-frame{
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height:10vmin;
  width: 30vmin; 
  border-radius: 15vmin;
  padding: 5px;
}
.frame-red{
  background-color: rgb(230, 154, 154);
  border: 3px solid rgb(120, 28, 28);
}
.frame-yellow{
  background-color: rgb(240, 224, 120);
  border: 3px solid rgb(185, 158, 0);
}
.frame-green{
  background-color: rgb(162, 240, 120);
  border: 3px solid rgb(3, 159, 3);
}
.frame-coral{
  background-color: rgb(240, 150, 120);
  border: 3px solid rgb(237, 92, 1);
}
.frame-pink{
  background-color: rgb(241, 149, 227);
  border: 3px solid rgb(241, 16, 147);
}
.frame-turkois{
  background-color: rgb(149, 241, 236);
  border: 3px solid rgb(0, 234, 215);
}
.frame-orange{
  background-color: rgb(241, 204, 149);
  border: 3px solid rgb(234, 140, 0);
}
.frame-black{
  background-color: rgb(128, 128, 128);
  border: 3px solid rgb(0, 0, 0);
}
.frame-blue{
  background-color: rgb(149, 198, 241);
  border: 3px solid rgb(0, 109, 234);
}

.button-tab{
  display:block;
  height:8vmin;
  width: 8vmin;
  border-radius: 4vmin;
  box-shadow: 1px 0px 4px 0px rgba(0,0,0,0.75);;
}

.button-tab-yellow{
  border: 4px solid rgb(255, 255, 255);
  background-color: rgb(252, 210, 0);
}
.button-tab-red{
  border: 4px solid rgb(255, 255, 255);
  background-color: rgb(176, 55, 55);
}
.button-tab-green{
  border: 4px solid rgb(255, 255, 255);
  background-color: rgb(0, 209, 63);
}
.button-tab-blue{
  border: 4px solid rgb(255, 255, 255);
  background-color: rgb(0, 94, 209);
}
.button-tab-black{
  border: 4px solid rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
}
.button-tab-orange{
  border: 4px solid rgb(255, 255, 255);
  background-color: rgb(255, 115, 0);
}
.button-tab-coral{
  border: 4px solid rgb(255, 255, 255);
  background-color: rgb(255, 94, 0);
}
.button-tab-turkois{
  border: 4px solid rgb(255, 255, 255);
  background-color: rgb(0, 251, 255);
}
.button-tab-pink{
  border: 4px solid rgb(255, 255, 255);
  background-color: rgb(255, 0, 166);
}

.button-label{
  font-size:3vmin;
  margin-left: 4px;
  font-weight: 500;
}
.table-cont{
  width:90vmin;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}
.response-text-box{
  width:90vmin;
  display:flex;
  margin-top: 7vmin;
  flex-direction: column;
  align-items:flex-start;
  justify-content: center;

}
.comment-field-btn-cont{
  width:90vmin;
  display:flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
  align-content: center;

}

.inst-text{
  text-align: left;
  align-self: baseline;
  font-size: 16pt;
}

.title-cont{
  display: flex;
  flex-direction: column;
  width: 90vmin;
 
}

.f1-label{
  font-size: 10pt;
  color: white;
}

.submit-btn-props{
  margin-left: 2vmin;
  margin-right: 2vmin;
  background-color: rgba(0,0,0,0.75);
  color: white;
  border-radius: 2vmin;
}

.logos{
  display: inline;
  margin-left: 3vmin;
  margin-right: 3vmin;
}

.lang-btn{
  border: 0ch white;
  margin-left: 2vmin;
  margin-right: 2vmin;
  background-color: white;
  color: red;
}

.alert{
  font-size: 8pt;
  color: red;
}

.bold-lang-label{
  color: rgb(53, 53, 53);
  font-size: 12pt;
}

.appreciation-content{
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;


}

.contact-card{
  background-color: rgb(219, 219, 219);
  display: flex;
  flex-direction: column;
  border-radius: 3vmin;
  margin-top: 8vmin;
  padding:3vmin;
}

.contact-card #email{
  color:rgb(0, 132, 255);
  margin-top: 2vmin;
}

.name-researcher-contact-card{
  font-weight: 500;
}

.scrollable-agreement{
  overflow-y: scroll;
  max-height: 70vmin;
}

.duration-cont{
  background-color: rgb(216, 216, 216);
  border-radius: 1vmin;
  padding: 1vmin;
  padding-left: 2vmin;
}

.table-custom{
  border-collapse:collapse;
  border-style : hidden!important;
  border:0;
  
}

.text-box-custom{
  margin-bottom: 5vmin;
}
.submit-btn-custom{
  padding: 2vmin;
  width: 60vmin;
}

.footer-text-custom{
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 10pt;
}
.nav-bar-layout-custom{
  display: flex;
  align-content: space-between;
  justify-content: center;
}
.nav-all-holder{
  display: flex;
  flex-direction: column;
  gap: 7vmin;
  flex-shrink:1;
  
}

.terms-cond-btn{
  display: flex;
  justify-content: center;
  margin-top: 3vmin;
}

@media(min-width:600px){
  .nav-all-holder{
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    align-self: center;
  }
}

.lang-setting-layout{
  display: flex;
  align-content: space-between;
  justify-content: center;
}

